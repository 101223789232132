// action types
export const SIGN_IN = 'SIGN_IN'  //登陆
export const LOG_OUT = 'LOG_OUT'//登出
export const SHOW_LOGIN_PAGE = 'SHOW_LOGIN_PAGE'  //显示登陆页面浮层
export const HIDE_LOGIN_PAGE = 'HIDE_LOGIN_PAGE'//隐藏登陆页面浮层

// action creators
// 新增待办事项
export function signInFunc(obj) {
  return { type: SIGN_IN, obj}
}
// 修改某个待办事项的状态，index是待办事项在todos数组中的位置索引
export function logOutFn() {
  return { type: LOG_OUT }
}
// action creators
export function loginPageShow() {
  return { type: SHOW_LOGIN_PAGE }
}
// 修改某个待办事项的状态，index是待办事项在todos数组中的位置索引
export function hideLoginPage() {
  return { type: HIDE_LOGIN_PAGE }
}

const userData = {
  blog_degnji: 0,
  blog_fangwen: 0,
  blog_guanzhu: "0",
  blog_jifen: 0,
  is_login: false,
  loginPageIsDisplay: false,
  register_timer: "",
  uid: "",
  user_add: "",
  user_add_time: "",
  user_birth: "",
  user_company: "",
  user_explain: "",
  user_head_pic: "",
  user_home: "",
  user_name: "",
  user_occ: "",
  user_school: "",
  user_sex: "",
  user_tel: "",
  classifyarr:[],
  tagarr:[],
}



// 处理todos的reducer
export function DengluReducer(state = userData, action) {
  switch (action.type) {
    case SIGN_IN:
      return {...state,...action.obj}
    case LOG_OUT:
      state.is_login = false;
      state.uid =""
      return { ...state }
    case SHOW_LOGIN_PAGE:
      state.loginPageIsDisplay = true;
      return { ...state }
    case HIDE_LOGIN_PAGE:
      state.loginPageIsDisplay = false;
      return { ...state }
    default:
      return state
  }
}
