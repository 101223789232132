import React from 'react'
import { Input, Button, Checkbox} from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import { hideLoginPage, signInFunc, logOutFn } from '../../redux/auth'
import { AlterModal, OtherTishi} from '../../utils/commonlyUsed'

import { AxiosPost, AxiosGet,doMain} from '../../utils/AxiosPack';

import './index.css'
import { Link } from 'react-router-dom';



class HomeLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      userName:"",
      userPassWorld:"",
      autoLogin:false,
    }
  }
 
  inputOnChange=(e)=>{
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  async onUserLoginOut() {
    let res = await AxiosGet('/loginOut');
    if (res.data.result == "1") {
      this.props.userLoginOut()
    } else {
      AlterModal("接口报错");
    }
  }

 async subMitFunc(e){
   let obj = { name: this.state.userName, pass:this.state.userPassWorld}
   var isLogin = await AxiosPost('/login',obj);
   var datas = isLogin.data;
   if (datas.result != -1){
     var result_obj = datas.data;
     result_obj.loginPageIsDisplay = false;
     result_obj.is_login = true;
     this.props.userLoginFun(result_obj)
   }else{
     AlterModal(datas.data.act)
   }
  }
  render() {
    if (this.props.authObj.is_login) {
      return (<>
        <div className="loginCont">
          <div className="logined-user-info">
            <Link to={{ pathname: '/u/main/' + this.props.authObj.uid, state: { prehref: this.props.prehref } }} >
              <img src={doMain()+this.props.authObj.user_head_pic} />
              <span className="logined-name">{this.props.authObj.user_name}</span>
            </Link> 
            <span className="logout-btn" onClick={() => { this.onUserLoginOut();}}>退出</span>
          </div>
          <ul>
            <li>等级<span>{this.props.authObj.blog_degnji}</span></li>
            <li>积分<span>{this.props.authObj.blog_jifen}</span></li>
            <li>访问量<span>{this.props.authObj.blog_fangwen}</span></li>
          </ul>
          <div className="logined-bottom-btn">
            <a className="to-set-interest" href="#" onClick={() => { OtherTishi("系统正在升级中......")}}>兴趣设置</a>
            <Link to={{ pathname: '/writeblog' }} >写博客</Link>
          </div>
        </div>
      </>)
    } else {
      return (<>
        <div className="loginCont">
          <p>成山博友，欢迎回来！</p>
          <Input prefix={<UserOutlined />} className='inputs' name="userName" placeholder="用户名/手机号" value={this.state.userName} onChange={this.inputOnChange} />
          <Input.Password prefix={<LockOutlined />} className='inputs' name="userPassWorld" placeholder="请输入密码" value={this.state.userPassWorld} onChange={this.inputOnChange } />
          <div className="onLoginCont">
            <Button type="primary" className='loginbtn' onClick={(e) => { this.subMitFunc(e)}}>登陆</Button>
            <Checkbox className="checkbox" onChange={(e) => { this.setState({ autoLogin: e.target.checked}) }}>下次自动登录</Checkbox>
          </div>
          <div className="end">
            <span> <Link to={{ pathname: '/signup', state: { prehref: this.props.prehref } }} onClick={() => { this.props.hideLoginPage1()}} >立即注册</Link></span>
            <span onClick={() => { OtherTishi("系统正在升级中......")}}>忘记密码</span>
          </div>
        </div>
      </>)
    }

  }
}


function mapStateToProps(state) {
  return {
    authObj: state.DengluReducer
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideLoginPage1: function () {
      dispatch(hideLoginPage())
    },
    userLoginFun:function (obj) {
      dispatch(signInFunc(obj))
    },
    userLoginOut:function () {
      dispatch(logOutFn())
    }
  }
}

const HomeLoginModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeLogin)

export default HomeLoginModel;