import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom';

import HomePage from '../container/home/home'
import NavCont from '../component/nav/nav'
import { ButtomComponent } from '../component/bottom/index'
import LoginPageConect from '../container/loginPage/index'
import WriteBlog from '../container/writeBlog/writeblog'
import updaTainformationClass from '../container/updatainformation/updatainformation'
import BlogSearch from "../container/blogsearch/blogsearch"

import MainClass from '../container/main/main'
import BlogPostCatalog from "../container/blogcatalog/blogPostCatalog"
import BlogArticle from "../container/blogarticle/blogarticle"
import MyPage from "../container/blogmy/mypage"
import SignupClass from '../container/signup/signup'
import { AxiosPost } from '../utils/AxiosPack';

import { connect } from 'react-redux'
import { loginPageShow, signInFunc, logOutFn } from '../redux/auth'

const routerArr = [
  {
    path: '/',
    component: HomePage,
    exact: true,
    nav: true,
  }, {
    path: '/signup',
    component: SignupClass,
    exact: true,
    nav: false,
  }, {
    path: '/updatainformation',
    component: updaTainformationClass,
    exact: true,
    nav: true,
  }, {
    path: '/writeblog',
    component: WriteBlog,
    exact: true,
    nav: true,
  }, {
    path: '/search/:id',
    component: BlogSearch,
    exact: true,
    nav: true,
  },{
    path: '/u',
    component: (props) => <RoutNeiPage {...props} />,
    nav: true,
  }
]


class UserRouteConnect extends React.Component {
  constructor(props){
    super(props)
  }
  componentDidMount() {
    this.isOnLoader();
  }

  async isOnLoader() {
    let res = await AxiosPost('/islogin');
    if (res.data.result == "1"){
      var result_obj = res.data.data;
      result_obj.loginPageIsDisplay = false;
      result_obj.is_login = true;
      this.props.loginFunc(result_obj)
    }else{
      this.props.logLoutFun();
    }
  }

  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          {
            routerArr.map((item) => (
              <MyRoute key={item.path} {...item} {...this.props} />
            ))
          }
        </Switch>
      </Router>
    )
  }

  
}

function MyRoute({ component: Component, ...rest }) {
  return(<>
    <Route {...rest} render={(props) => {  return (
      <>
        {rest.nav ? <NavCont  {...rest} {...props} /> : null}
        <Component {...rest}   {...props}/>
        <ButtomComponent />
        <LoginPageConect  {...rest} {...props} />
      </>)
    }}/>
  </>)
}


function RoutNeiPage(rest){
  const { path, url, params } = useRouteMatch()
  return(<>
    <Switch>
      <Route path={`${path}/main/:id`} render={(props) => (<MainClass authObj={rest.authObj} {...props} />)} />
      <Route path={`${path}/blog/:id/:type`} render={(props) => (<BlogPostCatalog authObj={rest.authObj}  {...props} />)} />
      <Route path={`${path}/my/:id`} render={(props) => (<MyPage authObj={rest.authObj}  {...props} />)} />
      {/* 文章  */}
      <Route path={`${path}/profile/:id`} render={(props) => (<BlogArticle authObj={rest.authObj} {...props} />)} /> 
      <Route  path={`${path}/`} component={() => <h3>404</h3>} />
    </Switch>
  </>)
}



function mapStateToProps(state) {
  return {
    authObj: state.DengluReducer
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showPage: function () {
      dispatch(loginPageShow())
    },
    loginFunc: function (obj){
      dispatch(signInFunc(obj));
    },
    logLoutFun:function(){
      dispatch(logOutFn());
    }
  }
}

const UserRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRouteConnect)





export default UserRoute;