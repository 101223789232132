import React from 'react'

import BlogSlotClass from '../../component/blogslot/blogslot'
import PersonalData from '../../component/blogpersonaldata/personaldata'
import BlogArticleContent from '../../component/blogArticleContent/blogarticlecontent'
import { AlterModal } from '../../utils/commonlyUsed'


import {AxiosPost } from '../../utils/AxiosPack';

// 博文 页面

class BlogArticle extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      user_id:"",
      blogger_name:"",
      blogger_data:{},
      article_data:{},
      comment_data:{}
    }
  }
  componentDidMount() {
    //在第一次渲染后调用
    this.onLoaderDAta();
  }
  async onLoaderDAta(){
    var article_id = this.props.match.params.id;
    let res = await AxiosPost('/getArticle', { id: article_id });
    if (res.data.result == "-1") {
      AlterModal(res.data.data.act)
    } else {
      let obj = res.data.data;
      this.setState({
        user_id: obj.articleData.uid,
        article_data: obj.articleData,
        blogger_data: obj.bloggerData,
        comment_data: obj.commentData,
        blogger_name: obj.bloggerData.user_name
      })
    }
  }
  render() {
    return (<>
      <BlogSlotClass userId={this.state.user_id} userName={this.state.blogger_name} currentNav="blog">
        <PersonalData Blogger={this.state.blogger_data}  data-postion="left" />
        <BlogArticleContent {...this.props} articleData={this.state.article_data} commentData={this.state.comment_data} data-postion="main" />
      </BlogSlotClass>
    </>)
  }
}

export default BlogArticle