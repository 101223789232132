import React from "react"
import { Input, Button } from 'antd';
import UpdataXinxiCont from '../../component/updataXinxi/updataXinxi'
import { AxiosPost } from '../../utils/AxiosPack';
import { deleteFrontAndBehindBlank, userNameIsOk, AlterModal, isMobile,isPassWord} from '../../utils/commonlyUsed'


import './signup.css'


class SignupClass extends React.Component{
  constructor(props){
    super(props)
    let backurl = "/";
    if (this.props.location){
      if (this.props.location.state){
        backurl = this.props.location.state.prehref
      }
    }
    this.state = {
      backgroundImage: "/img/signup/reg_bg_x.jpg",
      backgroundImage2: "/img/signup/reg_bg.jpg",
      user_name:"",
      user_tel:"",
      user_pass1:"",
      user_pass2:"",
      backurl,
    }
    console.log(props)
  }
  onInputChangeFun=(e)=>{
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  updataXinxi =(obj)=>{
    this.setState(obj)
  }


  async subMit() {

    if (!userNameIsOk(this.state.user_name)){
      AlterModal("请输入合法的名字")
      return;
    }
    if (!isMobile(this.state.user_tel)) {
      AlterModal("请输入正确的手机号码")
      return;
    }
    console.log(this.state.user_pass1, this.state.user_pass2)
    if (this.state.user_pass1 !== this.state.user_pass2) {
      AlterModal("两次输入的密码不同")
      return;
    }
    if (!isPassWord(this.state.user_pass1)) {
      AlterModal("请输入合法的密码，长度大于6且小于20")
      return;
    }


    var obj = {
      user_name:deleteFrontAndBehindBlank(this.state.user_name),
      user_tel:this.state.user_tel,
      pass:deleteFrontAndBehindBlank(this.state.user_pass1),
      user_sex:this.state.user_sex,
      user_birth: this.state.user_birth,
      user_occ: this.state.user_occ,
      user_add: this.state.user_add,
      user_home: this.state.user_home,
      user_school: this.state.user_school,
      user_company: this.state.user_company,
      user_explain: this.state.user_explain
    }
    let zhuce = await AxiosPost('/register', obj);
    if (zhuce.data.result == "-1") {
      AlterModal(zhuce.data.data.act);
      return;
    }else{
      // 注册成功
      var result_obj = zhuce.data.data;
      result_obj.loginPageIsDisplay = false;
      result_obj.is_login = true;
      this.props.loginFunc(result_obj)
      this.props.history.push(this.state.backurl)
    }
  }


  
  render(){
    return(<>
      <div className="signupBody" style={{ backgroundImage: "url(" + this.state.backgroundImage + ")"}}>
        <div className="codebg" style={{ backgroundImage: "url(" + this.state.backgroundImage2 + ")" }}>
          {/* <img className="bg" src="/img/signup/reg_bg.jpg"/> */}
          <div className="signupmargn">
            <h1>成山博客注册</h1>
            <div className="cent">
              <div className='item bitianItem'>
                <p className='item_name'>昵称</p>
                <Input placeholder="用户名" name="user_name" value={this.state.user_name} onChange={this.onInputChangeFun} />
              </div>
              <div className='item bitianItem'>
                <p className='item_name'>手机号码</p>
                <Input placeholder="手机号码" name="user_tel" value={this.state.user_tel} onChange={this.onInputChangeFun} maxLength={11} />
              </div>
              <div className='item bitianItem'>
                <p className='item_name'>密码</p>
                <Input.Password placeholder="密码" name="user_pass1" value={this.state.user_pass1} onChange={this.onInputChangeFun}  />
              </div>
              <div className='item bitianItem'>
                <p className='item_name'>再次确认</p>
                <Input.Password placeholder="密码" name="user_pass2" value={this.state.user_pass2} onChange={this.onInputChangeFun}  />
              </div>
              <UpdataXinxiCont onInputChangeFun={this.updataXinxi} />

              <Button className="submit" onClick={() => { this.subMit()}}>注册</Button>

            </div>

          </div>
        </div>
       

    </div>
    </>)
  }
}

export default SignupClass;