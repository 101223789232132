import React from "react"
import { Link } from 'react-router-dom';
import './blogtypelist.css'
function BlogTypeList(props) {
  if (props.classifyarr != undefined){
    var typeCont = 0;
    for(var i = 0; i < props.classifyarr.length; i ++){
      typeCont += parseInt(props.classifyarr[i][1]);
    }
    var allCurrentClass = '';
    if (props.types === "all") {
      allCurrentClass = "current";
    }
    return (<div className='blogComp blogTypeList'>
      <div className="title">博文</div>
      <ul>
        {
        props.classifyarr.length === 0 ? (
          <li>
              <Link to={{ pathname: '/u/blog/' + props.userId+"/all" }} >
                <div className="item current"><span className="type">全部博文</span><span className="nums">({typeCont})</span></div>
              </Link>
          </li>) : (
          <>
          <li>
            <Link to={{ pathname: '/u/blog/' + props.userId + "/all" }} >
              <div className={"item " + allCurrentClass}><span className="type">全部博文</span> <span className="nums">({typeCont})</span></div>
            </Link>
          </li>
          {props.classifyarr.map((item,index)=>{
            var listClass=''
            if (item[0] === props.types){
              listClass ="current";
            }
            return (
              <li key={index}>
                <Link to={{ pathname: '/u/blog/' + props.userId + "/" + item[0] }} >
                  <div className={"item " + listClass}><span className="type">{item[0]}</span> <span className="nums">({item[1]})</span></div>
                </Link>
              </li>
            )
          })}
        </>)}
      </ul>
    </div>)
  }else{
    return null;
  }
}

export default BlogTypeList;