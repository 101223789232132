import React from "react"

import './synopsis.css'


function SynopsisComp(props) {

  return (<div className='blogComp synopsisCont'>
    <div className="title">个人简介</div>
    <div className="text" dangerouslySetInnerHTML={{ __html: props.context }}></div>
  </div>)
  
}

export default SynopsisComp;