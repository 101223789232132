import React from 'react';
import ReactDOM from 'react-dom';
import './css/home.css'
import { Provider } from 'react-redux'
import store from './redux/store'
import UserRoute from './route/route'

ReactDOM.render(
  <Provider store={store}>
    <UserRoute />
  </Provider>,
  document.getElementById('root')
);
