import React from 'react'
import { Link } from 'react-router-dom';
import './blogslot.css'


class BlogSlotClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      backgroundImage2: "/img/home/blog_hard.jpg",
      pageMinH:400,
    }
  }
  componentDidMount() {  //在第一次渲染后调用
    this.setState({
      pageMinH: document.body.clientHeight-80
    })
    
  }



  render() {
    let colnumOne_cont=[], colnumTwo_cont=[];
    this.props.children.forEach((item, index) => {
      if (item.props["data-postion"] === "left") {
        colnumOne_cont.push(item);
      } else if (item.props["data-postion"] === "main") {
        colnumTwo_cont.push(item);
      }
    })
    return (<>
      <div className="chengshanBlogB" style={{ minHeight:this.state.pageMinH, backgroundImage: "url(" + this.state.backgroundImage2 + ")"}}>
        {/* 顶部 */}
        <div className="headarea">

          <div className="blogtoparea">
            <h1 className="blogtitle">{this.props.userName} 博客</h1>
            {/* <div className="bloglink">http://aaa.dddd.com.cn/ddd</div> */}
          </div>
          <div className="blognav">
            <div className="blognavInfo">
              <span className={this.props.currentNav == "main" ? "active" : ""}><Link to={{ pathname: '/u/main/' + this.props.userId }} >首页</Link></span>
              <span className={this.props.currentNav == "blog" ? "active" : ""}><Link to={{ pathname: '/u/blog/' + this.props.userId+"/all" }} >博文目录</Link></span>
              <span className={this.props.currentNav == "my" ? "active" : ""}><Link to={{ pathname: '/u/my/' + this.props.userId }} >关于我</Link></span>
            </div>
          </div>
          {/* <div className="blogtopoption"> 发表文章</div> */}

        </div>

        {/*  */}

        <div className="chegnshanblogbody">
          {/* 第一列 */}
          <div className="column_1">
            {colnumOne_cont.map((item)=>{
              return item
            })}
          </div>
          {/* 第二列 */}
          <div className="column_2">
            {colnumTwo_cont.map((item) => {
              return item
            })}
          </div>

        </div>

      </div>


    </>)
  }
}

export default BlogSlotClass