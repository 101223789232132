import axios from 'axios'
import qs from "qs"

axios.defaults.withCredentials = true; //跨域携带cookie

let httpCode = {
  400: '请求参数错误',
  401: '权限不足, 请重新登录',
  403: '服务器拒绝本次访问',
  404: '请求资源未找到',
  500: '内部服务器错误',
  501: '服务器不支持该请求中使用的方法',
  502: '网关错误',
  504: '网关超时'
}

export function doMain() {
  return "https://www.my520love.com/jiekou";
}

export function AxiosPostFile(_url, _data) {


  if (!_data) return;

  const formData = new FormData();
  formData.append('pic', _data.pic); // 上传文件
  formData.append('title', _data.title);


  let options = {
    method: 'POST',
    timeout: 5000,
    responseType: "json",
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: formData,
    url: doMain() +"/csbbsadmin"+ _url,
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      });
  })

}


export function AxiosPost(_url,_data={}) {

  let options = {
    method: 'POST',
    timeout: 5000,
    responseType: "json",
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(_data),
    url: doMain() + "/csbbsadmin" + _url,
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      });
  })
  
}

export function AxiosGet(_url, _data={}) {

  let options = {
    method: 'get',
    responseType: "json",
    params: _data,
    timeout: 1000,
    url: doMain() + "/csbbsadmin" + _url
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        //reject(error)
        errorFunc(error)
      });
  })
  
}

function errorFunc(error) {
  if (error.response) {
    // 根据请求失败的http状态码去给用户相应的提示
    let tips;// = error.response.status in httpCode ? httpCode[error.response.status] : error.response.data.message
    if (error.response.status in httpCode) {
      // if (error.response.data.message !== undefined && error.response.data.message !== '') {
      //   tips = error.response.data.message;
      // } else {
      //   tips = httpCode[error.response.status];
      // }
      alert(httpCode[error.response.status])
    }else{
      alert("请联系管理员")
    }
    
    //Toast.offline(tips, 2);
    //return Promise.reject(error);
  } else {
    //Toast.offline('请求超时，请刷新试试', 1);
    //return Promise.reject('请求超时, 请刷新重试')
    alert("请求超时，请刷新试试")
  }
}

