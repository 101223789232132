import React from "react"
import { Pagination } from 'antd'
import { Link } from 'react-router-dom';

import './homelist.css'


function BlogHomeList(props) {

  if (props.articleDataObj.length === undefined || props.articleDataObj.length ===0){
    return <p>您暂时还没有写文章呢</p>
  }

  return (<div className='blogComp blogHomeListCont'>
    <div className="title">博文</div>
    <div className="blogHomeList">

      {props.articleDataObj.map((item,index)=>{
        return(<div key={index}>
          <div className="blog_title_h">
            <Link to={{ pathname: '/u/profile/' + item.article_id }} ><div className="blog_title">{item.title}</div></Link>
            <span className="time">({item.timer})</span>
            {
              props.userId === props.authObj.uid ? (<>
                <Link to={{ pathname: '/writeblog', search: "?id=" + item.article_id }} className="CP_a_fuc" >[编辑]</Link>
                {/* <div className="CP_a_fuc">[删除]</div> */}
              </>) : null
            } 
           
          </div>
          <div className="articalTag">
            <span className="first">标签：</span>
            {item.article_tag.map((itemtap,indextap)=>{
              return <h3 key={indextap}>{itemtap}</h3>
            })}
            <span className="SG_txtb">分类：</span>
            <Link to={{ pathname: '/u/blog/' + item.uid + "/" + item.article_class }} ><div className="type">{item.article_class}</div></Link>
          </div>

          <div className="content" dangerouslySetInnerHTML={{ __html: item.connet }}></div>

          <div className="tagMore">
            <div className="tag"><Link to={{ pathname: '/u/profile/' + item.article_id }} >阅读</Link><span>({item.liulan_num})</span></div>
            <div className="tag"><Link to={{ pathname: '/u/profile/' + item.article_id, hash:"#commonComment" }} >评论</Link><span>({item.comment_num})</span></div>
          </div>
        </div>)
      })}

      <Pagination 
        hideOnSinglePage={true} //有一页的时候，隐藏
        defaultCurrent={1}  //默认的当前页数
        pageSize={10} //每页的条数
        total={props.paginationTol} //总数
        showQuickJumper //是否显示快速跳转按钮
        size="small" //尺寸大小
        showSizeChanger={false}
        onChange={props.paginationChange}  />
    </div>
    
  </div>)

}

export default BlogHomeList;