import React from "react"
import { Badge } from 'antd'
import { doMain } from '../../utils/AxiosPack';
import './personaldata.css'


function PersonalData(props) {
  return (<div className='blogComp personalDataCont'>
    <div className="title">个人资料</div>
    <img src={doMain()+props.Blogger.user_head_pic} />
    <div className="item dengji">博客等级：<span>{props.Blogger.blog_degnji}</span></div>
    <div className="item jifen">博客积分：<Badge count={"新"} offset={[10, 2]}><span>{qianFenHao(props.Blogger.blog_jifen)}</span></Badge></div>
    <div className="item">博客访问：<span>{qianFenHao(props.Blogger.blog_fangwen)}</span></div>
    <div className="item">关注人气：<span>{qianFenHao(props.Blogger.blog_guanzhu)}</span></div>
  </div>)
}

 function qianFenHao(str){
   if(str){
     let n = str.toString()
     let re = /\d{1,3}(?=(\d{3})+$)/g;
     let n1 = n.replace(/^(\d+)((\.\d+)?)$/, function (s, s1, s2) { return s1.replace(re, "$&,") + s2; });
     return n1;
   }
 }

export default PersonalData;