import React from "react"
import './home.css'
import HomeLeftNews from '../../component/homeleftnews/index'
import HomeLoginModel from '../../component/Login/index'
import DaKaSay from '../../component/dakashuo/index'
import MySwiper from '../../component/swiper/index'
import { BackUrl} from '../../utils/commonlyUsed'
import { Link } from "react-router-dom"

import homeJosn from "../../utils/home.json"

// import imgsrc1 from '../../img/home/1.jpg'
// 博客首页


class HomePage extends React.Component{
  constructor(props){
    super(props);
    this.state={
      tuijianObj:{
        title: "今日推荐",
        data: [
          { "ids": 1, "content": "三八节恰逢龙凤日，除了吃饺子，还做了这道菜" },
          { "ids": 2, "content": "怎么判断孩子长大后有没有出息？聪明父母就看3点" },
          { "ids": 3, "content": "你好，李焕英" },
          { "ids": 4, "content": "童安格26岁女儿近照曝光，清纯甜美" },
          { "ids": 5, "content": "summer 博客测试" },
          { "ids": 6, "content": "主要使用react，和ant " }
        ]
      },
      activeObj:{
        title: "活动专区",
        data: [
          { "ids": 1, "content": "周末宅家解闷神器，5本轻松不费脑的漫画书送给你" },
          { "ids": 2, "content": "8部观影指南，看完你就get了看电影的正确姿势" },
          { "ids": 3, "content": "2021年8月1日前免费开放react资料" },
          { "ids": 4, "content": "2021年8月1日前免费开放html资料" },
          { "ids": 5, "content": "2021年8月1日前免费开放nodejs资料" }
        ],
        pic: {
          "id": 222,
          "pic": "./img/home/active.jpg"
        }
      }
      
    }
    console.log(homeJosn)
  }
  render(){
    return(<>
      <div className="homepage">
        <div className="gg">
          <img src="./img/home/home_title.jpg"/>
        </div>
        <div className="homenav">
          <div className='homenavCent'>
            <ul>
              <li><Link to={{ pathname: '/search/HTML'}} >HTML</Link></li>
              <li><Link to={{ pathname: '/search/javascript' }} >javascript</Link></li>
              <li><Link to={{ pathname: '/search/css' }} >css </Link></li>
              <li><Link to={{ pathname: '/search/react' }} >react</Link></li>
            </ul>
          </div>
          
        </div>

        <div className="homeBody">
          
          <div className="blog-left">
            <HomeLeftNews newData={homeJosn.tuijianObj} />
            <HomeLeftNews newData={homeJosn.activeObj} />
          </div>
          <div className="blog-container">

            <div className="blog-center">
              <MySwiper kvdata={homeJosn.kvList} />

              <div className="blog-news">
                <div className="focus">
                  <div className="label">
                    <p className="label1">聚</p>
                    <p className="label2">焦</p>
                  </div>
                  <h2><Link to={{ pathname: '/u/profile/' + homeJosn.zhutui.article_id }}>{homeJosn.zhutui.content}</Link></h2>
                  <p className="normal"><span>热议</span><span className="border-left"><Link to={{ pathname: '/u/profile/' + homeJosn.reyi[0].article_id }}>{homeJosn.reyi[0].content}</Link></span><span className="border-left"><Link to={{ pathname: '/u/profile/' + homeJosn.reyi[1].article_id }}>{homeJosn.reyi[1].content}</Link></span></p>
                </div>
                <ul className="art-list">
                  {homeJosn.mainBlog.map((item,index)=>{
                    return (<li key={item.article_id}>
                      <span className="num">{index < 10 ? "0" + (index + 1) : (index+1)}</span>
                      <Link to={{ pathname: '/u/profile/' + item.article_id }}>{item.content}</Link>
                    </li>)
                  })}
                </ul>
              </div>

            </div>

            <div className="blog-right">
              <HomeLoginModel prehref={BackUrl(this.props)} />
              <DaKaSay dkdata={homeJosn.dakaishuo} />
            </div>
            </div>

          </div>


      </div>
    </>)
  }
}

export default HomePage;