import React from "react"
import { Link } from 'react-router-dom';
import { Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import WangEdirotClass from '../myWangEdirot/wangEdirotClass'
import './blogarticlecontent.css'
import { AlterModal } from '../../utils/commonlyUsed'
import { AxiosPost } from '../../utils/AxiosPack';

const { TextArea } = Input;
class BlogArticleContent extends React.Component{
  constructor(props){
    super(props)
    this.state={
      commTitText:"",
      commData:null,
      huifu_name:"-1",
      huifu_id:"-1",
      TextArea_init:"",
      TextArea_isinit:false,
      user_pinglun_ok:false,
      user_pinglun_num:0,
    }

   
  }
  textAreaChange=(e)=>{
    this.setState({
      commTitText: e.target.value
    });
  }
  subClickFun=()=>{
    this.wirteCommetFunc();
  }
  async wirteCommetFunc(){
    var obj = {};
    obj.user_id = this.props.articleData.uid
    obj.artical_id = this.props.articleData.article_id;
    if(obj.user_id === undefined || obj.artical_id === undefined){
      AlterModal("提交参数出错");
    } else if (this.state.commTitText === ""){
      alert("评论文本框不能为空");
    }else{
      if (this.state.huifu_name != -1 && this.state.huifu_id != -1){
        if(this.state.commTitText.indexOf("<p>@" + this.state.huifu_name +":</p>") !==0){
          this.setState({
            huifu_name: "-1",
            huifu_id: "-1",
          })
        }else{
          //这是回复
          obj.reply_id = this.state.huifu_id;
          obj.reply_name = this.state.huifu_name;
        }
      }
      obj.cont = this.state.commTitText;
      let res = await AxiosPost('/wirteComment',obj);
      if (res.data.result == "1") {
        this.setState({
          commTitText: "",
          commData: res.data.data,
          TextArea_isinit:true,
          huifu_name: "-1",
          huifu_id: "-1",
          TextArea_init: "",
          user_pinglun_ok:true,
          user_pinglun_num: res.data.data.length
        })
      } else {
        AlterModal(res.data.data.act)
      }
    }
  }
  onHuiFuFun(_name,_comment_id){
    this.setState({
      TextArea_init: '<p>@' + _name+':</p><br/>',
      TextArea_isinit:true,
      huifu_name:_name,
      huifu_id:_comment_id
    })
  }
  onEdirotChangeFun = html =>{
    this.setState({
      commTitText: html,
      TextArea_isinit: false
    })
  }
  render(){
    if (!this.props.articleData.title){
      return null;
    }
    if (this.props.articleData.result === "-1"){
      return (
        <div className='blogComp blogArticleContent'>
          <div className="articlebody">
            <div className="articalTitle">
              <h2 className="titleName ">您没有权限访问此篇文章</h2>
            </div>
          </div>
        </div>
      )
    }

    return (<div className='blogComp blogArticleContent'>
      <div className="title">正文</div>
      <div className="articlebody">
        <div className="articalTitle">
          <h2 className="titleName ">{this.props.articleData.title}</h2>
          <span className="timer">({this.props.articleData.timer})</span>
          {this.props.authObj.uid != this.props.articleData.uid ? null : (<>
            <Link to={{ pathname: '/writeblog', search: "?id=" + this.props.articleData.article_id }} >[编辑]</Link>
          </>)}
          
        </div>
        <div className="articalTag">
          <span className="cs_txtb">标签：</span>
          <span className="types">{this.props.articleData.article_tag ? this.props.articleData.article_tag.map((item)=>{
            return item +" "
          }) : null }</span>
          <span className="cs_txtb cs_txtbTwo">分类：</span>
          <Link to={{ pathname: '/u/blog/' + this.props.articleData.uid + "/" + this.props.articleData.article_class }} >{this.props.articleData.article_class}</Link>
        </div>

        <div className="newfont_family" dangerouslySetInnerHTML={{ __html: this.props.articleData.connet }}></div>
        
        {this.props.articleData.result === "-2" ? (<div className="tishi">登录后，访问全文...</div>): null}

        <div className="articalInfo">阅读<span>({this.props.articleData.liulan_num})</span><span>┊</span><a href="#commonComment">评论</a><span>({this.props.articleData.comment_num >= this.state.user_pinglun_num ? this.props.articleData.comment_num:this.state.user_pinglun_num})</span></div>
        {/* 评论区 */}

        <div className="allComm">
          {/* {console.log(this.props.articleData)} */}
          {/* {this.props.articleData.comment_num === 0 ? null : (<> */}
          {(this.props.articleData.comment_num !== 0 || this.state.user_pinglun_ok) ?(<>
            <div className="allCommTit">
              <span>重要提示：警惕虚假中奖信息</span>
            </div>
            <ul className="article_comment_list">
              {this.state.commData === null ? (<>
                {this.props.commentData.map((item, index) => {
                  return (
                    <li key={index}>
                      <p className="commTitNanme">
                        <a href={"/u/main/" + item.commentator_id}>{item.name}</a>
                      </p>
                      <div className="commTitCont" dangerouslySetInnerHTML={{ __html: item.text }}></div>
                      <div className="myReFrom">
                        <span className="timers">{item.timer}</span>
                        <span className='huifu'><a onClick={() => { this.onHuiFuFun(item.name, item.comment_id)}} href="#commonComment">回复</a></span>
                      </div>
                    </li>
                  )
                })}
              </>) : (<>
                {this.state.commData.map((item,index)=>{
                  return (
                    <li key={index}>
                      <p className="commTitNanme">
                        <a href={"/u/main/" + item.commentator_id}>{item.name}</a>
                      </p>
                      <div className="commTitCont" dangerouslySetInnerHTML={{ __html: item.text }}></div>
                      <div className="myReFrom">
                        <span className="timers">{item.timer}</span>
                        <span className='huifu'><a onClick={() => { this.onHuiFuFun(item.name, item.comment_id) }} href="#commonComment">回复</a></span>
                      </div>
                    </li>
                  )
                })}
                 
              </>)}
            </ul>
          </>):null}
          <div className="allCommTit commtit" id="commonComment">
            <strong>发评论</strong>
          </div>
          {/* <div dangerouslySetInnerHTML={{ __html: this.state.commTitText }}></div> */}
          <div className="wrCommTit">
            <WangEdirotClass onEdirotChangeFun={this.onEdirotChangeFun} initvalue={this.state.TextArea_init} TextArea_isinit={this.state.TextArea_isinit} ishead={false} />
            <Button className="subCommTit" onClick={this.subClickFun} >发评论</Button>
            <div className="tips">以上网友发言只代表其个人观点，不代表成山网的观点或立场。</div>
            {this.props.authObj.is_login ? null : (
              <div className="onMask">
                <LockOutlined className="onlock" />
                <p>登陆后才可以评论</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>)

  }
}

export default BlogArticleContent;