import React from "react"
import wangEdirot from 'wangeditor'
import './wangEdirotClass.css'
import { AxiosPostFile, doMain } from '../../utils/AxiosPack';

class WangEdirotClass extends React.Component{
  constructor(props){
    super(props);
    this.state={
      editor:null
    }
  }
  componentDidMount() {
    const elem = this.refs.editorElem
    const editor = new wangEdirot(elem)
    // 使用 onchange 函数监听内容的变化，并实时更新到 state 中
    editor.config.onchange = html => {
      this.props.onEdirotChangeFun(html);
    }

    editor.config.menus = [
      'head',  // 标题
      'bold',  // 粗体
      'fontSize',  // 字号
      'fontName',  // 字体
      'italic',  // 斜体
      'underline',  // 下划线
      'strikeThrough',  // 删除线
      'indent',
      'todo',//复选框
      'lineHeight',//行高
      'foreColor',  // 文字颜色
      'backColor',  // 背景颜色
      'indent',//缩进
      'link',  // 插入链接
      'list',  // 列表
      'justify',  // 对齐方式
      'quote',  // 引用
      'emoticon',  // 表情
      // 'image',  // 插入图片
      'table',  // 表格
      // 'video',  // 插入视频
      //'code',  // 插入代码
      'undo',  // 撤销
      'redo',  // 还原
      'splitLine', //添加一个横线
    ]

    editor.config.uploadImgShowBase64 = true;
    // 配置自定义参数（举例）
    editor.config.uploadParams = {
      token: 'abcdefg',
      user: 'wangfupeng1988'
    };

    // 设置 headers（举例）
    editor.config.uploadHeaders = {
      'Accept': 'text/x-json'
    };
  

    editor.config.colors = [// 配置颜色（文字颜色、背景色）
      '#000000', '#ffffff', '#ff0000', '#00ff00', '#0000ff', '#e58587', '#815758','#e612e4',
      '#1a1ebf', '#0973df', '#00ffba', '#7fc928', '#0000ff', '#ecfa14', '#95968a', '#cccccc'
    ]
    editor.config.showFullScreen = true; //配置全屏
    editor.config.uploadFileName = 'fileName'  //自定义 fileName
    editor.config.uploadImgServer = doMain() + "/csbbsadmin/savepic";//可以本地上传图片 但是需要接口支持
    editor.config.withCredentials = false;  //设置跨域请求

    // 自己实现上传图片
    editor.config.customUploadImg = async function (resultFiles, insertImgFn) {
      // resultFiles 是 input 中选中的文件列表
      // insertImgFn 是获取图片 url 后，插入到编辑器的方法

      // 上传图片，返回结果，将图片插入到编辑器中
      // insertImgFn(imgUrl)
      console.log( resultFiles, insertImgFn)


      var fd = new FormData();
      // fd.append('fname', 'test.wav');
      fd.append('data', resultFiles);

      let savePicData = await AxiosPostFile('/savepic', fd);
      var datas = savePicData.data;
      
    }

    

    editor.config.showLinkImg = false;  //需要上传图片功能，而不需要插入网络图片功能
    
    // editor.txt.html('<p>用 JS 设置的内容</p>') // 可以直接改变编辑器内容
    //editor.txt.append('<p>追加的内容</p>') //继续追加内容

    // editor.config.linkImgCallback = function (src) {//插入网络图片的回调事件
    //   console.log('图片 src ', src)
    // }

    editor.config.height = this.props.heightnum ? this.props.heightnum : 150;
    // editor.$txt.html('<p>要初始化的内容</p>');

   
    editor.create()
    this.setState({
      editor: editor
    })
    // if (editor) {
    //   editor.txt.html('<p>用 JS 设置的内容</p>');
    // }
    if (this.props.initvalue !== "") {
      editor.txt.html(this.props.initvalue);
    }
    
  }
  componentWillReceiveProps(nextProps){
   
    if (nextProps.TextArea_isinit){
      if (nextProps.initvalue != ""){
        this.state.editor.txt.html(nextProps.initvalue);
      }else{
        this.state.editor.txt.html(`<p></p>`);
      }
    }

  }


  render(){
    return (<>
      <div className={this.props.ishead ? "myEdirot" :"myEdirot noHead"}>
        <div className="edirot" ref="editorElem" > </div>
      </div>
    </>)
  }
}

export default WangEdirotClass;