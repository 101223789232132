import React from 'react'
import { Input, Select, DatePicker } from 'antd';
import WangEdirotClass from '../../component/myWangEdirot/wangEdirotClass'

import './updataXinxi.css'

const { Option } = Select;

class UpdataXinxiCont extends React.Component{
  constructor(props){
    super(props);
    this.state={
      user_sex: "m", //性别
      user_birth: "", //生日
      user_occ:"", //职业
      user_add: "", //现在居住地址
      user_home: "", //家庭地址
      user_school: "", //学校
      user_company:"", //公司
      user_explain:"" //个人介绍
    }
    this.props.onInputChangeFun(this.state)
  }

  onEdirotChangeFun = html => {
    this.setState({
      user_explain: html
    })
    this.props.onInputChangeFun({ user_explain: html })
  }
  onSelectChange=(e)=>{
    this.setState({
      user_sex: e
    });
    this.props.onInputChangeFun({ user_sex:e})
  }
  onDatePickerChange = (date, dateString)=>{
    this.setState({ user_birth: dateString})
    this.props.onInputChangeFun({ user_birth: dateString})
  }
  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
    this.props.onInputChangeFun({ [e.target.name]: e.target.value })
  }
  render(){
    return(<>
    <div className='updataXinxiCont'>
        <div className='item'>
          <p className='item_name'>性别</p>
          <Select defaultValue="m" name="user_sex" value={this.state.user_sex} onChange={this.onSelectChange} >
            <Option value="m">男</Option>
            <Option value="w">女</Option>
          </Select>
        </div>
        <div className='item'>
          <p className='item_name'>生日</p>
          <DatePicker style={{ width: '100%' }} onChange={this.onDatePickerChange} />
        </div>
        <div className='item'>
          <p className='item_name'>职业</p>
          <Input placeholder="职业" name="user_occ" value={this.state.user_occ} onChange={this.onInputChange} />
        </div>
        <div className='item'>
          <p className='item_name'>现在居住地址</p>
          <Input placeholder="居住地址" name="user_add" value={this.state.user_add} onChange={this.onInputChange} />
        </div>
        <div className='item'>
          <p className='item_name'>家乡</p>
          <Input placeholder="家乡" name="user_home" value={this.state.user_home} onChange={this.onInputChange}  />
        </div>
        <div className='item'>
          <p className='item_name'>学校</p>
          <Input placeholder="学校" name="user_school" value={this.state.user_school} onChange={this.onInputChange} />
        </div>
        <div className='item'>
          <p className='item_name'>公司</p>
          <Input placeholder="公司" name="user_company" value={this.state.user_company} onChange={this.onInputChange} />
        </div>
        <div className='item'>
          <p className='item_name'>个人简介</p>
          <WangEdirotClass onEdirotChangeFun={this.onEdirotChangeFun} ishead={false} />
        </div>
    </div>
      
    </>)
  }
}

export default UpdataXinxiCont;