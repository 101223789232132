import React from 'react';
import WangEdirotClass from '../../component/myWangEdirot/wangEdirotClass'
import { AlterModal, deleteFrontAndBehindBlank,getRequest} from '../../utils/commonlyUsed'

import { Input, Select, Modal, Radio, Button  } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import { AxiosGet, AxiosPost } from '../../utils/AxiosPack';

import "./writeblog.css"

const { Option } = Select;
var RadioGroup = Radio.Group;

// 写文章
let writeBlogEvenListen;
let writeBlogDie = false;

class WriteBlog extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isBtnClick:false,
      blog_title:"",
      userClassStr:"",
      danxuan_val:"public",//文章权限
      text_indent: 0, //tag input输入框中的光标位置
      tagContPlay:"none",//tag 下拉的显示问题
      tagInputVal: "",//tag input输入框的值
      tagArr:[],//input中显示的标签
      changyongTagArr:[],//常用的tag（后台读取）
      bodyhtml: '',//文章的主体
      isModalVisible: false, //修改我们分类的浮层显示问题
      typeInput_val: "",//修改我们分类的浮层中的input值
      typeDataArr: [[]],//这是分类下拉框中的数据
      typeDisplayArr: [],//这是分类浮层中显示的数据（这个不一定全部有效，当点击取消后，刚设置的数据就没有用了）
      TextArea_init: "",
      TextArea_isinit: false,
      pageMinH: 400,
    }
    this.saveRef = ref => { this.refDom = ref };
  }
  componentDidMount() {  //在第一次渲染后调用
    writeBlogDie = false;
    this.onLoaderDAta();
    this.setState({
      pageMinH: document.body.clientHeight - 80
    })
    writeBlogEvenListen = this.props.history.listen((a, b, c) => {
      setTimeout(() => {
        this.onLoaderDAta();
      }, 10);
    })
  }
  componentWillUnmount(){//消除组件前
    writeBlogDie = true;
    writeBlogEvenListen && writeBlogEvenListen(); // 执行解绑
  }
  componentWillReceiveProps(nextProps){//props值改变的时候，调用
    if (this.props.authObj.is_login !== nextProps.authObj.is_login) {
      if (nextProps.authObj.is_login) {
        this.onLoaderDAta();
      }
    }
    
  }
  async onLoaderDAta(a) {
  if (writeBlogDie) {
      return null;
    }
    var article_id =""
    var obj = getRequest(this.props.location.search)
    if(obj.id){
      article_id = obj.id
    }
    let res = await AxiosPost('/getArticleData', { id: article_id });
    if (res.data.result != "-1") {
      var result_obj1 = res.data.data.userData.classifyarr;
      var result_obj2 = res.data.data.userData.tagarr
      this.setState({
        typeDataArr: result_obj1,
        changyongTagArr: result_obj2
      })
      var updataData = res.data.data.articleData;
      if (updataData){
        this.setState({
          blog_title: updataData.title,
          TextArea_init: updataData.connet,
          userClassStr: updataData.article_class,
          tagArr: updataData.article_tag.split(","),
          danxuan_val: updataData.quanxian,
          TextArea_isinit: true
        })
      }
    }
  }


  // 这是我们获取的权限设置
  onDanXuanFun=(e)=>{
    this.setState({
      danxuan_val: e.target.value
    });
  }
  // 控制标签下拉的显示
  tageFocusIn=(e)=>{
    this.setState({
      tagContPlay:"block"
    })
  }
  tagContDisplayFun =()=>{
    this.setState({
      tagContPlay: "none"
    })
  }
 // 添加我们的文字标签
  tageChangeFun=(e)=>{
    var input_val = e.target.value;
    if (input_val !== " "){
      let rge = /.*\s$/g
      if (rge.test(input_val)) {
        this.onTagAddFunc(input_val);
      }else{
        this.setState({
          tagInputVal: input_val
        })
      }
      
    }else{
      this.setState({
        tagInputVal: ""
      })
    }
  }
  tagEndDwon = ()=>{
    if (this.state.tagInputVal !== ""){
      this.onTagAddFunc(this.state.tagInputVal);
      
    }
  }
  tageClickFun=(e)=>{
    e.preventDefault();
    this.onTagAddFunc(e.target.dataset.names)
  }
  onTagAddFunc= (str)=>{
    str = deleteFrontAndBehindBlank(str)
    var arr = this.state.tagArr;
    if (arr.length >= 5){
      AlterModal("标签最多5个")
      return 
    }
    var flg = 0;
    for(let i = 0; i < arr.length; i ++){
      if(arr[i] == str){
        flg = 1; 
       break;
      }
    }
    if(flg === 1){
      AlterModal("此标签已经存在了")
      return;
    }
    arr.push(str)
    this.setState({
      tagInputVal:"",
      tagArr: arr
    })
    setTimeout(() => {
      const { clientWidth } = this.refDom;
      this.setState({
        text_indent: clientWidth
      })
    }, 300);
    
  }
  // 删除我们的文字标签
  tagCloseFun = (e) => {
    this.onTagDelFun(e.target.dataset.names)
  }
  onTagDelFun = (str)=>{
    var _idx = 0;
    var arr = [...this.state.tagArr]
    for(var i = 0 ; i < arr.length; i ++)
    {
      if(arr[i] === str){
        _idx = i; 
        break;
      }
    }
    arr.splice(_idx,1);
    this.setState({
      tagArr:[...arr]
    })
    setTimeout(() => {
      const { clientWidth } = this.refDom;
      this.setState({
        text_indent: clientWidth
      })
    }, 300);
  }

  // 修改我们的文字分类
  handleOk =()=>{
    let str1 = this.state.typeDataArr.toString();
    let str2 = this.state.typeDisplayArr.toString();
    if(str1 == str2){
      //console.log("没有改变");
      this.handleCancel();
    }else{
      let data_arr = [...this.state.typeDisplayArr]
      let sub_str=""
      for(var i = 0; i < data_arr.length; i ++){
        if(i == 0){
          sub_str = data_arr[i][0];
        }else{
          sub_str = sub_str + "," + data_arr[i][0]
        }
      }
      this.setState({
        isModalVisible: false
      })
      // 传递接口
      this.updadataArticleClass(sub_str, data_arr)
    }
  }
  async updadataArticleClass(str, data_arr) {

    if (!this.state.isBtnClick) {
      this.setState({ isBtnClick: true })
      let res = await AxiosPost('/updadataArticleClass', { typeStr: str });
      if (res.data.result == "-1") {
        AlterModal(res.data.data.act)
      } else {
        this.setState({
          typeDataArr: data_arr
        })
      }
      this.setState({ isBtnClick: false })
    }
  }
  //隐藏我们的管理分类框
  handleCancel=()=>{
    this.setState({
      isModalVisible: false
    })
  }
  //弹出我们的管理分类框
  ModalDisplayFunc=()=>{
    var arr = [...this.state.typeDataArr]
    this.setState({
      typeDisplayArr:arr,
      isModalVisible:true
    })
  }

  // 分类的文本框
  typeInputChange=(e)=>{
    this.setState({
      typeInput_val:e.target.value
    })
  }
  // 管理者添加自己的一个分类
  onAddTypeFun = ()=>{
    var arr2 = [...this.state.typeDisplayArr];
    if(arr2.length >= 15){
      AlterModal("最大分类不可超过15个")
      return
    }
    var _str = deleteFrontAndBehindBlank(this.state.typeInput_val)
    if(_str == ""){
      AlterModal("分类名字不可以什么都没有");
      return
    }
    var flg = 0; //判断是否已含有  0 表示满意，1表示有
    for (var i = 0; i < arr2.length; i ++){
      if(arr2[i][0] == _str){
        flg = 1;
        break;
      }
    }
    if(flg == 1){
      AlterModal("分类已经存在，不需要重新创建");
      return;
    }
    var arr = [_str,0]
    arr2.unshift(arr);
    this.setState({
      typeDisplayArr:arr2,
      typeInput_val:""
    })
  }
  // 管理者删除自己的一个分类
  onDelTypeFun=(e)=>{
    if (e.target.dataset.nums > 0){
      AlterModal("此分类中含有其他文章，请将文章分类切换成其他分类或者删除")
      return;
    }
    var arr = [...this.state.typeDisplayArr];
    var _del_type = e.target.dataset.values;
    var _idx = 0;
    for (var i = 0; i < arr.length; i ++){
      if (arr[i][0] === _del_type){
        _idx = i;
      }
    }

    arr.splice(_idx, 1);
    this.setState({
      typeDisplayArr: [...arr]
    });
  }
  // 获取写的文字主体部分
  onEdirotChangeFun = html => {
    this.setState({
      bodyhtml: html,
      TextArea_isinit: false
    })
  }
  // 文章的标题改变
  onBlogTitleChange=(e)=>{
    this.setState({
      blog_title: e.target.value
    })
  }
  // 用户选择的分类
  userClassChange=(val)=>{
    // console.log(val)
    this.setState({
      userClassStr: val
    })
  }

  // 提交写的文章
  subMitFun = () => {
    let postObj = {};
    postObj.articleName = deleteFrontAndBehindBlank(this.state.blog_title);
    if(postObj.articleName === ""){
      AlterModal("标题不能为空")
      return;
    }
    postObj.articleBody = this.state.bodyhtml;
    if (postObj.articleBody === "") {
      AlterModal("文章内容不能为空")
      return;
    }
    postObj.articleClass = this.state.userClassStr;
    if (postObj.articleClass === "") {
      AlterModal("请选择文章的分类")
      return;
    }
    postObj.articleTag = this.state.tagArr.join(",");
    if (postObj.articleTag ==="") {
      AlterModal("请设置文章的标签，标签数最少为1个")
      return;
    }
    postObj.quanxian = this.state.danxuan_val;
    // postObj.tag = this.props.authObj.tag
    postObj.article_id = ""
    var obj = getRequest(this.props.location.search)
    if (obj.id) {
      postObj.article_id = obj.id
    }
    this.postActicle(postObj)
  }

  async postActicle(data){
    if (!this.state.isBtnClick){
      this.setState({ isBtnClick: true })

      // let zhegnze = /(?<=<img src="data:).*?(?=\")/g
      // let base64Arr = data.articleBody.match(zhegnze);

      // var obj = { pic: [...base64Arr] }
      // console.log(obj)
      // let savePicData = await AxiosPost('/savepic', obj);
      // var datas = savePicData.data;

      let res = await AxiosPost('/wirteArticle', data);
      if (res.data.result == "1") {
        this.setState({ isBtnClick: false })
        this.props.history.push('/u/profile/' + res.data.data.articleId)
      } else {
        AlterModal(res.data.data.act)
        this.setState({ isBtnClick: false })
      }
    }
    
  }


  render(){
    if (!this.props.authObj.is_login){
      return (<div className="writePage" style={{ minHeight: this.state.pageMinH }}>
        <p className="noLoading">您好没有登陆，无法使用发布文章</p>
      </div>);
    }
    return(<>
    <div className="writePage" style={{ minHeight: this.state.pageMinH }}>
      <div className="writePageCent">
          <h1>发博文</h1>
          <div className="titles">
            标题：<Input placeholder="文章的标题" value={this.state.blog_title} onChange={this.onBlogTitleChange} />
          </div>
          <WangEdirotClass onEdirotChangeFun={this.onEdirotChangeFun} heightnum="500" ishead={true} initvalue={this.state.TextArea_init} TextArea_isinit={this.state.TextArea_isinit} />
          <div className="types">
            <span>分类：</span>
            <Select defaultValue={this.state.userClassStr} style={{ width: 260}} onChange={this.userClassChange}>
              <Option value="">选择分类</Option>
              {this.state.typeDataArr.map((item, index) => {
                return (<Option key={index} value={item[0]}>{item[0]}</Option>)
              })}
            </Select>
            <p onClick={this.ModalDisplayFunc}>创建分类</p>
          </div>
          <div className="tag">
            <span>标签：</span>
            <div className="tagmain">
              <div className="taginputCont">
                <Input 
                  onFocus={this.tageFocusIn} 
                  onChange={this.tageChangeFun} 
                  onPressEnter={this.tagEndDwon}
                  style={{ textIndent: this.state.text_indent }} 
                  value={this.state.tagInputVal} />
                <div className="distag" ref={this.saveRef}>
                  {this.state.tagArr.map((item,index)=>{
                    return <span key={index} >{item}<span onClick={this.tagCloseFun} data-names={item}>x</span></span>
                  })}
                </div>
              </div>
            </div>
            <div className="tagCont" style={{ display: this.state.tagContPlay}}>
              <CloseOutlined style={{ fontSize: '16px', position:'absolute',top:'10px',right:'10px',cursor:'pointer' }} onClick={this.tagContDisplayFun} />
              <p>标签间请用“空格”或“逗号”隔开，最多可添加5个标签。</p>
              {this.state.changyongTagArr.length < 1 ? null : (
                <div className="dingyitags">
                  <span>常用标签：</span>
                  <div className="dingyi">
                    {this.state.changyongTagArr.map((item,index)=>{
                      return (<span key={index} data-names={item} onClick={this.tageClickFun} >{item}</span>)
                    })}
                  </div>
                </div>
              )}
              <div className="xitongtags">
                <span>推荐标签：</span>
                <div className="xitong">
                  <span data-names={"seo"} onClick={this.tageClickFun} >seo</span>
                  <span data-names={"html"} onClick={this.tageClickFun} >html</span>
                  <span data-names={"js"} onClick={this.tageClickFun} >js</span>
                  <span data-names={"css"} onClick={this.tageClickFun} >css</span>
                  <span data-names={"node"} onClick={this.tageClickFun} >node</span>
                  <span data-names={"es6"} onClick={this.tageClickFun} >es6</span>
                  <span data-names={"ngix"} onClick={this.tageClickFun} >ngix</span>
                </div>
              </div>
            </div>
            
          </div>

          <div className="setUp">
            <span>设置：</span>
            <RadioGroup onChange={this.onDanXuanFun} value={this.state.danxuan_val}>
              <Radio value="public">允许所有人查看</Radio>
              <Radio value="limit">允许登陆后查看</Radio>
              <Radio value="private">仅自己查看</Radio>
            </RadioGroup>
          </div>


          <Button className='subBtns' type="primary" onClick={this.subMitFun}>发博文</Button>


      </div>

      <Modal 
        title="分类管理" 
        cancelText="取消"
        okText="确定"
        visible={this.state.isModalVisible} 
        onOk={this.handleOk} 
        centered={true}
        keyboard={false}
        maskClosable={false}
        onCancel={this.handleCancel}>
          <div className="titles">
            <Input placeholder="最多可输入10个中文字符" value={this.state.typeInput_val} onChange={this.typeInputChange} />
            <Button onClick={this.onAddTypeFun}>创建分类</Button>
            <span className="zhushi">使用中文,英文和数字</span>
          </div>
          <div className="fenglei_cont">
            {this.state.typeDisplayArr.map((item,index)=>{
              if(index %2 == 0){
                return (<p key={index}>{item[0]}({item[1]}) <span data-values={item[0]} data-nums={item[1]} onClick={this.onDelTypeFun}>[删除]</span></p>)
              }else{
                return (<p key={index} className="old">{item[0]}({item[1]}) <span data-values={item[0]} data-nums={item[1]} onClick={this.onDelTypeFun}>[删除]</span></p>)
              }
            })}

          </div>

      </Modal>

    </div>
    
    </>)
  }
}

export default WriteBlog;