import React from 'react';
import NavLogoIn from './navlogin'
import { Link } from 'react-router-dom';
import { Input, Space } from 'antd';
import './nav.css'
const { Search } = Input;
class NavCont extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      searchvalue: ""
    }
    console.log(props)
  }

  onsearchOnChang = (e) => {
    this.setState({
      searchvalue: e.target.value
    })
  }
  onSearch = () => {
    this.props.history.push("/search/" + this.state.searchvalue)
    // this.props.location
  }

  render(){
    return(<>
      <div className="pagenavcont">
      <div className="pagenav">
          <div className="bokename"><Link to={{ pathname: '/'}} >成山博客</Link></div>
          <Search
            value={this.state.searchvalue}
            placeholder="搜索您要找的内容"
            className="onsearchInput"
            size="small"
            onChange={this.onsearchOnChang}
            onSearch={this.onSearch}
            style={{ width: 200 }} />
          <NavLogoIn {...this.props} />
      </div>

    </div>
    </>)
  }
}

export default NavCont;