import React from 'react'
import { connect } from 'react-redux'
import { hideLoginPage } from '../../redux/auth'
import HomeLoginModel from '../../component/Login/index'
import { BackUrl } from '../../utils/commonlyUsed'
import './index.css'

class LoginPage extends React.Component{
  constructor(props){
    super(props)
  }
  render(){
    if (this.props.loginPageIsDisplay){
      return (<>
        <div className="loginPageCont">
          <div className="loginPageCent">
            <HomeLoginModel prehref={BackUrl(this.props)}  />
            <div className="close" onClick={() => { this.props.hideLoginPage1() }}></div>
          </div>
        </div>
      </>)
    }else{
      return null
    }
    
  }
}

function mapStateToProps(state) {
  return {
    loginPageIsDisplay: state.DengluReducer.loginPageIsDisplay
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideLoginPage1: function () {
      dispatch(hideLoginPage())
    }
  }
}

const LoginPageConect = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage)

export default LoginPageConect;
