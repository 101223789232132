import {message, Modal } from 'antd'


// 把html转化为str
export function htmlToStr(str){
  if (!str) return '';
  str = str.replace(/&/g, '&amp;');
  str = str.replace(/</g, '&lt;');
  str = str.replace(/>/g, '&gt;');
  str = str.replace(/"/g, '&quto;');
  str = str.replace(/'/g, '&#39;'); 
  str = str.replace(/ /g, "&nbsp;");
  str = str.replace(/↵/g, "&huiche;");
  return str;
}
// 把str转化为html
export function strToHtml(str) {
  if (!str) return '';
  str = str.replace(/&amp;/g, '&');
  str = str.replace(/&lt;/g, '<');
  str = str.replace(/&gt;/g, '>');
  str = str.replace(/&quto;/g, '\"');
  str = str.replace(/&#39;/g, '\'');
  str = str.replace(/&nbsp;/g, "");
  return str;
}
// 放回当前连接
export function BackUrl(props) {
  let to_url = '/'
  let obj = props.location
  if(obj){
    to_url = obj.pathname + obj.search + obj.hash 
  }
  return to_url;
}
// 删除字符串前后的空格
export function deleteFrontAndBehindBlank(str) {
  let front_reg = /^\s*/;
  let behind_reg = /\s*$/;
  return str.replace(front_reg, "").replace(behind_reg, "")
}

// 用户名验证
export function userNameIsOk(str) {
  str = deleteFrontAndBehindBlank(str)
  let reg = /^[\w\u2E80-\u9FFF]{2,10}$/;
  return reg.test(str);
}
// 手机验证
export function isMobile(str) {
  let reg =/^1[3456789]\d{9}$/
  return reg.test(str);
}
// 密码验证
export function isPassWord(str) {
  str = deleteFrontAndBehindBlank(str)
  let reg = /^[\w-\!@#\$%&\*]{6,20}$/
  return reg.test(str);
}

// 时间显示  (时间戳，Y-M-D h:m:s)
export function dateFormat(str, fmt) {
  let date = new Date(parseInt(str));
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "M+": (date.getMonth() + 1).toString(),     // 月
    "D+": date.getDate().toString(),            // 日
    "h+": date.getHours().toString(),           // 时
    "m+": date.getMinutes().toString(),         // 分
    "s+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    };
  };
  return fmt;
}
// 计算2个时间的差值（startDate,endDate）  都是时间戳
export function getDiffYmdBetweenDate(sDate1, sDate2) {
  var fixDate = function (sDate) {
    var aD = sDate.split('-');
    for (var i = 0; i < aD.length; i++) {
      aD[i] = fixZero(parseInt(aD[i]));
    }
    return aD.join('-');
  };
  var fixZero = function (n) {
    return n < 10 ? '0' + n : n;
  };
  var fixInt = function (a) {
    for (var i = 0; i < a.length; i++) {
      a[i] = parseInt(a[i]);
    }
    return a;
  };
  var getMonthDays = function (y, m) {
    var aMonthDays = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if ((y % 400 == 0) || (y % 4 == 0 && y % 100 != 0)) {
      aMonthDays[2] = 29;
    }
    return aMonthDays[m];
  };
  var y = 0;
  var m = 0;
  var d = 0;
  var sTmp;
  var aTmp;
  sDate1 = fixDate(dateFormat(sDate1,"Y-M-D"));
  sDate2 = fixDate(dateFormat(sDate2, "Y-M-D"));
  if (sDate1 > sDate2) {
    return { y: -1, m: -1, d: -1 }
  }
  var aDate1 = sDate1.split('-');
  aDate1 = fixInt(aDate1);
  var aDate2 = sDate2.split('-');
  aDate2 = fixInt(aDate2);
  //计算相差的年份
  y = aDate2[0] - aDate1[0];
  if (sDate2.replace(aDate2[0], '') < sDate1.replace(aDate1[0], '')) {
    y = y - 1;
  }
  //计算月份
  aTmp = [aDate1[0] + y, aDate1[1], fixZero(aDate1[2])];
  while (true) {
    if (aTmp[1] == 12) {
      aTmp[0]++;
      aTmp[1] = 1;
    } else {
      aTmp[1]++;
    }
    if (([aTmp[0], fixZero(aTmp[1]), aTmp[2]]).join('-') <= sDate2) {
      m++;
    } else {
      break;
    }
  }
  //计算天数
  aTmp = [aDate1[0] + y, aDate1[1] + m, aDate1[2]];
  if (aTmp[1] > 12) {
    aTmp[0]++;
    aTmp[1] -= 12;
  }
  while (true) {
    if (aTmp[2] == getMonthDays(aTmp[0], aTmp[1])) {
      aTmp[1]++;
      aTmp[2] = 1;
    } else {
      aTmp[2]++;
    }
    sTmp = ([aTmp[0], fixZero(aTmp[1]), fixZero(aTmp[2])]).join('-');
    if (sTmp <= sDate2) {
      d++;
    } else {
      break;
    }
  }
  return { y: y, m: m, d: d }
}

// alert
export function AlterModal(msg)  {
  Modal.error({
    title: '错误提示！',
    centered: true,
    okText: "关闭",
    maskClosable: true,
    width: 400,
    content: (
      <>
        {msg}
      </>
    ),
  });
}
// success
export function SuccessModal(msg) {
  Modal.success({
    title: '',
    centered: true,
    okText: "关闭",
    maskClosable: true,
    width: 400,
    content: (
      <>
        {msg}
      </>
    ),
  });
}

// 其他提示
export function OtherTishi(msg) {
  message.destroy()
  message.warning({
    content: msg,
    style: {
      marginTop: '30vh',
    },
  });
}
// 把传过来的url转化为一个对象
export function getRequest(url) {
  // var url = location.search;
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    var strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]);
    }
  }
  return theRequest;
}

export function addQianFenHao(n) {
  if(n === undefined || n === null || n === ""){
    return "";
  }
  var n = n.toString()
  var re = /\d{1,3}(?=(\d{3})+$)/g;
  var n1 = n.replace(/^(\d+)((\.\d+)?)$/, function (s, s1, s2) { return s1.replace(re, "$&,") + s2; });
  return n1;
}