
import './index.css'

export function ButtomComponent() {
  return(<>
  <div className="buttomCont">
      <div id="gongshang">
        <a href="https://beian.miit.gov.cn" target="_blank"><span>沪ICP备17056446号-1</span></a>
      </div>
  </div>
  </>)
}