import React from "react"
import { Link } from 'react-router-dom';
import './my.css'

function My(props){
  return (
    <div className='blogComp blogMyCont'>
      <div className="title">我的档案：</div>
      <div className="myItem">
        <div className="titles">基本信息</div>
        <div className="item">性&nbsp;&nbsp;别：<span>{ props.Blogger.user_sex==="m" ? "男" : "女" }</span></div>
        <div className="item">生&nbsp;&nbsp;日：<span>{props.Blogger.user_birth}</span></div>
        <div className="item">职&nbsp;&nbsp;业：<span>{props.Blogger.user_occ}</span></div>
        <div className="item">现居地：<span>{props.Blogger.user_add}</span></div>
        <div className="item">家&nbsp;&nbsp;乡：<span>{props.Blogger.user_home}</span></div>
      </div>
      <div className="myItem">
        <div className="titles">个人经历：</div>
        <div className="item">学&nbsp;&nbsp;校：<span>{props.Blogger.user_school}</span></div>
        <div className="item">公&nbsp;&nbsp;司：<span>{props.Blogger.user_company}</span></div>
      </div>
      <div className="myItem jianjie">
        <div className="titles">个人简介：</div>
        <div className="item" dangerouslySetInnerHTML={{ __html: props.Blogger.user_explain }}></div>
      </div>
      {
        props.userId === props.Blogger.uid ? (<>
          <div className="updata"> <Link to={{ pathname: '/updatainformation' }} >[编辑]</Link></div>
        </>) : null
      } 
     
    </div>
  )

}

export default My;