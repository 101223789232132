import { createStore } from 'redux'
import todoApp from './reduxconnet'

let store = createStore(todoApp)
// let next = store.dispatch
// store.dispatch = function dispatchAndLog(action) {
//   console.log('dispatching', action)
//   let result = next(action)
//   console.log('next state', store.getState())
//   return result
// }
// let unsubscribe = store.subscribe(() =>
//   console.log(store.getState())
// )

export default store;