import React from "react"
import { Link } from "react-router-dom"
import './homeleftnews.css'

class homeLeftNews extends React.Component{
  constructor(props){
    super(props)
    // this.state={
    //   is_show: this.props.newData.pic !== undefined
    // }
  }
  render(){
    if (!this.props || !this.props.newData || this.props.newData.data.length < 1 ) {
      return null;
    } else {
      return (<>
        <div className="homeLeftNewCont">
          <div className="titles">{this.props.newData.title}</div>
          { this.props.newData.pic !== undefined ? (<div className="pics" >
            <Link to={{ pathname: '/u/profile/' + this.props.newData.pic.article_id }}>
              <img src="./img/home/active.jpg" />
            </Link>
          </div>) : null}
          <ul>
            {this.props.newData.data.map((item) => {
              return (
                <Link to={{ pathname: '/u/profile/' + item.article_id }} key={item.article_id}>
                  <li>{item.content}</li>
                </Link>
              )
            })}
          </ul>
        </div>
      </>)

    }
   
  }

}

export default homeLeftNews;