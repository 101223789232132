import React from 'react'

import BlogSlotClass from '../../component/blogslot/blogslot'
import BlogTypeList from '../../component/blogTypeList/blogtypelist'
import BlogAllArticle from '../../component/blogAllArticle/blogallarticle'


import { AlterModal } from '../../utils/commonlyUsed'
import { AxiosPost } from '../../utils/AxiosPack';

// 博文目录页面
let catalogEvenListen;
let catalogDie = false;
class BlogPostCatalog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isdie:false,
      article_type:"all",
      blog_id:"",
      blogger_data: {},
      article_data:{},
      isdel:true
    }
  }
  componentDidMount() {
    //在第一次渲染后调用
    catalogDie = false;
    this.onLoaderDAta();
    catalogEvenListen = this.props.history.listen((a, b, c) => {
      setTimeout(() => {
        this.onLoaderDAta();
      }, 10);
    })
  }
  componentWillUnmount() {
    catalogDie = true;
    catalogEvenListen && catalogEvenListen(); // 执行解绑
  }
  async onLoaderDAta() {
    if (catalogDie){
      return null
    }
    var blog_id = this.props.match.params.id
    var article_type = this.props.match.params.type;
    let res = await AxiosPost('/getBlogge', { id: blog_id, type: article_type });
    if (res.data.result == "-1") {
      AlterModal(res.data.data.act)
    } else {
      let obj = res.data.data;
      var _classifyarr = obj.bloggerData.classifyarr;
      for(var i =0; i < _classifyarr.length; i ++){
        var class_str = _classifyarr[i][0];
        var class_num = 0;
        for (var j = 0; j < obj.articleClassObj.length; j ++){
          if (obj.articleClassObj[j].article_class === class_str){
            class_num ++;
          }
        }
        _classifyarr[i][1] = class_num;
      }
      obj.bloggerData.classifyarr = _classifyarr;
      this.setState({
        blogger_data: obj.bloggerData,
        article_data: obj.articleDataObj,
        article_type: article_type,
        isdel:true
      })
    }
  }
  delArticleFunc=(article_id)=>{
    if(this.state.isdel){
      this.setState({isdel: false})
      this.delFunc(article_id)
    }
    
  }
  async delFunc(article_id){
    let delArticle = await AxiosPost('/delArticle', { article_id:article_id });
    if (delArticle.data.result == "-1") {
      AlterModal(delArticle.data.data.act)
    } else {
      this.onLoaderDAta();
    }
  }
  render() {
    return (<>
      <BlogSlotClass userId={this.state.blogger_data.uid} userName={this.state.blogger_data.user_name} currentNav="blog" >
        <BlogTypeList userId={this.state.blogger_data.uid} classifyarr={this.state.blogger_data.classifyarr} types={this.state.article_type}  data-postion="left" />
        <BlogAllArticle delArticleFunc={this.delArticleFunc} data-postion="main" userId={this.state.blogger_data.uid}  authObj={{ ...this.props.authObj}} articleData={this.state.article_data} />
      </BlogSlotClass>
    </>)
  }
}

export default BlogPostCatalog