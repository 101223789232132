import React from "react";
import { connect } from 'react-redux'
import { logOutFn } from '../../redux/auth'
import { AxiosGet } from '../../utils/AxiosPack';
import { AlterModal } from '../../utils/commonlyUsed'

class LoginOut extends React.Component{
  constructor(props){
    super(props);
  }
  async onUserLoginOut(){
    let res = await AxiosGet('/loginOut');
    if (res.data.result == "1") {
      this.props.LogOutFn();
    } else {
      AlterModal("接口报错");
    }
  }

  render(){
    return(<>
      <div className='navlitm login_out' onClick={() => { this.onUserLoginOut() }}>退出</div>
    </>)
  }
}


function mapDispatchToProps(dispatch) {
  return {
    LogOutFn: function () {
      dispatch(logOutFn())
    }
  }
}

const LoginOutConnect = connect(
  null,
  mapDispatchToProps
)(LoginOut)

export default LoginOutConnect;