import React from "react"
import { Link } from 'react-router-dom';
import { Input, Select, DatePicker, Collapse,Button  } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import WangEdirotClass from '../../component/myWangEdirot/wangEdirotClass'
import moment from 'moment';
import { getDiffYmdBetweenDate } from '../../utils/commonlyUsed'
import UpdataHeadPic from '../../component/updataHearPic/updataHearPic'

// import UpdataXinxiCont from '../../component/updataXinxi/updataXinxi'
import { AxiosPost, doMain } from '../../utils/AxiosPack';
import { addQianFenHao, SuccessModal, AlterModal, isMobile, isPassWord } from '../../utils/commonlyUsed'


import { connect } from 'react-redux'
import { signInFunc } from '../../redux/auth'


import './updatainformation.css'
const { Panel } = Collapse;
const { Option } = Select;
const dateFormatMoment = 'YYYY-MM-DD';
const MAX_FILE_SIZE = 5 * 1024 * 1024 // 文件最大限制为5M

class updaTainformation extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      user_sex: "m", //性别
      user_birth: "", //生日
      user_occ: "", //职业
      user_add: "", //现在居住地址
      user_home: "", //家庭地址
      user_school: "", //学校
      user_company: "", //公司
      user_explain: "", //个人介绍
      TextArea_isinit: true,
      isbtnClick:true,
      classModalVisible: false,
      classModalFile: null,
      inputFileValue:"",
      pageMinH: 400,
    }
  }
  componentDidMount(){
    this.onLoaderStats(true)
    this.setState({
      pageMinH: document.body.clientHeight - 80
    })
  }
  componentWillReceiveProps(nextProps) {//props值改变的时候，调用

    if (this.props.authObj.is_login !== nextProps.authObj.is_login) {
      if (nextProps.authObj.is_login) {
         setTimeout(()=>{
          this.onLoaderStats(true)
         },20)
      }
    }
  }
  onLoaderStats=(isOnloader)=>{
    if(isOnloader){
      let obj = this.props.authObj;
      let bbs_age ="";
      if (obj.register_timer !== ""){

        let dis_obj = getDiffYmdBetweenDate(obj.register_timer, new Date().getTime())
        if(dis_obj.y === -1){
          console.log("非法")
        }else{
          if (dis_obj.y>0){
            bbs_age=dis_obj.y+"年"
          }
          if (dis_obj.m> 0) {
            bbs_age += dis_obj.m + "月"
          }
          if (dis_obj.d > 0) {
            bbs_age += dis_obj.d + "日"
          }
        }
      }
      this.setState({
        blog_age: bbs_age,
        user_sex: obj.user_sex,
        user_birth: obj.user_birth,
        user_occ: obj.user_occ,
        user_add: obj.user_add,
        user_home: obj.user_home,
        user_school: obj.user_school, //学校
        user_company: obj.user_company, //公司
        user_explain: obj.user_explain //个人介绍
      })
    }
  }
  onSelectChange = (e) => {
    this.setState({
      user_sex: e
    });
  }
  onDatePickerChange = (date, dateString) => {
    this.setState({ user_birth: dateString });
  }
  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  onEdirotChangeFun = html => {
    this.setState({
      user_explain: html,
      TextArea_isinit: false
    })
  }
  onUpdataClickBtn=()=>{
    if (this.state.isbtnClick){
      this.setState({
        isbtnClick:false
      })
      this.onSubFunc();
    }else{
      AlterModal("操作太平凡，请稍后再试");
    }
  }
  async onSubFunc(){
    let obj={};
    obj.user_sex= this.state.user_sex; //性别
    obj.user_birth= this.state.user_birth; //生日
    obj.user_occ= this.state.user_occ; //职业
    obj.user_add= this.state.user_add; //现在居住地址
    obj.user_home= this.state.user_home; //家庭地址
    obj.user_school= this.state.user_school; //学校
    obj.user_company= this.state.user_company; //公司
    obj.user_explain= this.state.user_explain; //个人介绍

    let zhuce = await AxiosPost('/updataPersonal', obj);
    if (zhuce.data.result == "-1") {
      AlterModal(zhuce.data.data.act);
      return;
    } else {
      // 修改数据成功
      SuccessModal("修改数据成功");
      this.props.userUpdataChange(obj);
      this.setState({
        isbtnClick: true
      })
    }
  }

  handleClassFileChange = e => {
    // this.setState({
    //   inputFileValue: e.target.value
    // })
    const file = e.target.files[0]
    if (file) {
      if (file.size <= MAX_FILE_SIZE) {
        this.setState(
          {
            classModalFile: file, // 先把上传的文件暂存在state中
            inputFileValue: e.target.value
          },
          () => {
            this.setState({
              classModalVisible: true, // 然后弹出modal
              inputFileValue: ""
            })
          }
        )
      } else {
        AlterModal("文件过大")
      }
    }
  }

  handleGetResultImgUrl = blob => {
    // console.log(blob)
    // const str = URL.createObjectURL(blob)
    // console.log(str)
    // this.setState({
    //   [key]: str
    // })
    // console.log(blob)
    this.upDataPicFunc(blob);
  }

  async upDataPicFunc(blob) {
    if (this.state.isbtnClick) {
      this.setState({
        isbtnClick: false
      })
      let savePicData = await AxiosPost('/savehead64', { pic: blob, title: "1234" });
      var datas = savePicData.data;
      if (datas.result != -1) {
        let result_obj = {};
        result_obj.user_head_pic = datas.data.user_head_pic;
        this.props.userUpdataChange(result_obj)
      } else {
        AlterModal(datas.data.act)
      }
      this.setState({
        isbtnClick: true
      })
    } else {
      AlterModal("操作太平凡，请稍后再试");
    }
  }

  render() {
    const {
      classModalVisible,
      classModalFile
    } = this.state
    return (<>
      {!this.props.authObj.is_login ? (<div className="updataTainFormationPage" style={{ minHeight:this.state.pageMinH}}>
        <p className="noLoading">您还没有登陆</p>
      </div>):(<>
        <div className="updataTainFormationPage" style={{ minHeight: this.state.pageMinH }}>
          {/* 上面显示内容 */}
          <div className="myCont">
            <div className="myContCent">
              <div className="myMain">
                <img src={doMain() + this.props.authObj.user_head_pic} />
                <p className="username">Torres的博客</p>
                <p className="fangwen">博客访问: <span>{addQianFenHao(this.props.authObj.blog_fangwen)}</span></p>
                <p className="ages">博客年龄: <span>{this.state.blog_age}</span></p>
                <u className="mycont_nav">
                  <li><Link to={{ pathname: '/u/main/' + this.props.authObj.uid }} >我的博客</Link></li>
                  <li><Link to={{ pathname: '/u/blog/' + this.props.authObj.uid + "/all" }} >博文目录</Link></li>
                  <li><Link to={{ pathname: '/u/my/' + this.props.authObj.uid }} >关于我</Link></li>
                </u>
                <div className="updataHeadPic">
                  <div className="tishi">编辑</div>
                  <input
                    value = {this.state.inputFileValue}
                    type="file"
                    accept="image/jpeg,image/jpg,image/png"
                    className="base-upload-input"
                    onChange={this.handleClassFileChange}
                  />
                </div>
                
              </div>
              <div className="write_article_cont">
                <Link to={{ pathname: '/writeblog' }} ><EditOutlined className="write_article_btn" /></Link>
                <p>发博文</p>
              </div>
            </div>
          </div>

          {/* 这里使我们要修改的内容 */}
          <div className="updataMian">
            <div className="titles">修改给人资料</div>
            <Collapse className="onupdata_tips" defaultActiveKey={['1']}>
              <Panel header="个人资料" key="1">
                <div className='item'>
                  <p className='item_name'>性&nbsp;&nbsp;别</p>
                  <div className="upCont">
                    <Select defaultValue="m" name="user_sex" value={this.state.user_sex} onChange={this.onSelectChange}>
                      <Option value="m">男</Option>
                      <Option value="w">女</Option>
                    </Select>
                  </div>
                </div>
                <div className='item'>
                  <p className='item_name'>生&nbsp;&nbsp;日</p>
                  <div className="upCont">
                    {this.state.user_birth === "" ? <DatePicker placeholder="选择日期" style={{ width: '100%' }} value={null} onChange={this.onDatePickerChange} /> : <DatePicker style={{ width: '100%' }} value={moment(this.state.user_birth, dateFormatMoment)} onChange={this.onDatePickerChange} /> }
                  </div>
                </div>
                <div className='item'>
                  <p className='item_name'>职&nbsp;&nbsp;业</p>
                  <div className="upCont">
                    <Input placeholder="职业" name="user_occ" value={this.state.user_occ} onChange={this.onInputChange}  />
                  </div>

                </div>
                <div className='item'>
                  <p className='item_name'>现居地</p>
                  <div className="upCont">
                    <Input placeholder="居住地址" name="user_add" value={this.state.user_add} onChange={this.onInputChange}   />
                  </div>
                </div>
                <div className='item'>
                  <p className='item_name'>家&nbsp;&nbsp;乡</p>
                  <div className="upCont">
                    <Input placeholder="家乡" name="user_home" value={this.state.user_home} onChange={this.onInputChange}   />
                  </div>

                </div>
              </Panel>
              <Panel header="个人经历" key="2">
                <div className='item'>
                  <p className='item_name'>学&nbsp;&nbsp;校</p>
                  <div className="upCont">
                    <Input placeholder="学校" name="user_school" value={this.state.user_school} onChange={this.onInputChange}  />
                  </div>

                </div>
                <div className='item'>
                  <p className='item_name'>公&nbsp;&nbsp;司</p>
                  <div className="upCont">
                    <Input placeholder="公司" name="user_company" value={this.state.user_company} onChange={this.onInputChange} />
                  </div>
                </div>
              </Panel>
              <Panel header="个人简介" key="3">
                <div className='item'>
                  <p className='item_name jianjie_title'>个人简介</p>
                  <WangEdirotClass onEdirotChangeFun={this.onEdirotChangeFun} initvalue={this.state.user_explain} TextArea_isinit={this.state.TextArea_isinit} ishead={false} />

                </div>
              </Panel>
            </Collapse>

            <Button className="okBtns" size="large" onClick={this.onUpdataClickBtn} >确定修改</Button>

          </div>

        </div>
        {classModalVisible && (
          <UpdataHeadPic
            uploadedImageFile={classModalFile}
            onClose={() => {
              this.setState({ classModalVisible: false })
            }}
            onSubmit={this.handleGetResultImgUrl}
          />
        )}
      </>)}
    </>)
    
  }
}




function mapDispatchToProps(dispatch) {
  return {
    userUpdataChange: function (obj) {
      dispatch(signInFunc(obj))
    }
  }
}

const updaTainformationClass = connect(
  null,
  mapDispatchToProps
)(updaTainformation)



export default updaTainformationClass;