import React from 'react'

import BlogSlotClass from '../../component/blogslot/blogslot'
import PersonalData from '../../component/blogpersonaldata/personaldata'
import My from '../../component/my/my'
import { AlterModal } from '../../utils/commonlyUsed'
import { AxiosPost } from '../../utils/AxiosPack';

// 关于我
let myPageEvenListen;
let myPageDie = false;
class MyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogger_data: {}
    }
  } 
  componentDidMount() {
    //在第一次渲染后调用
    myPageDie = false;
    this.onLoaderDAta();
    myPageEvenListen = this.props.history.listen((a, b, c) => {
      setTimeout(() => {
        this.onLoaderDAta();
      }, 10);

    })
  }
  componentWillUnmount() {
    myPageDie = true;
    myPageEvenListen && myPageEvenListen(); // 执行解绑
  }
  async onLoaderDAta() {
    if (myPageDie){
      return null;
    }
    var article_id = this.props.match.params.id;
    let res = await AxiosPost('/getPersonal', { id: article_id });
    if (res.data.result == "-1") {
      AlterModal(res.data.data.act)
    } else {
      let obj = res.data.data;
      this.setState({
        blogger_data: obj.bloggerData
      })
    }
  }
  render() {
    return (<>
      <BlogSlotClass userId={this.state.blogger_data.uid} userName={this.state.blogger_data.user_name} currentNav="my" >
        <PersonalData Blogger={this.state.blogger_data} data-postion="left" />
        <My Blogger={this.state.blogger_data} userId={this.props.authObj.uid} data-postion="main" />
      </BlogSlotClass >
    </>)

      
  }
}

export default MyPage