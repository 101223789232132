import React from "react"
import { Link } from 'react-router-dom';
import { dateFormat } from '../../utils/commonlyUsed'
import { Pagination } from 'antd'
import './blogallarticle.css'


function BlogAllArticle(props) {
 
  if (props.articleData === undefined || props.articleData.length === 0 || props.articleData.length === undefined){
    return <div>当前还没有文章</div>;
  }
  return (<div className='blogComp blogAllArticle'>
    <div className="title">全部博文</div>
    <ul className="articles">
      {props.articleData.map((item,index)=>{
        return(
          <li key={index}>
            <div className="item">
              <Link to={{ pathname: '/u/profile/' + item.article_id }} >
                <span className="articleTitle">{item.title}</span>
              </Link>
              {
                props.userId === props.authObj.uid ? (<>
                  <span className="del" onClick={() => { props.delArticleFunc(item.article_id)}} >[删除]</span><span className="change"><Link to={{ pathname: '/writeblog', search: "?id=" + item.article_id}} >[编辑]</Link></span> <span className="timer">{dateFormat(item.timer, "Y-M-D hh:mm")}</span>
                </>) : (<>
                    <span className="timer">{dateFormat(item.timer, "Y-M-D hh:mm")}</span>
                </>)
              } 
            </div>
          </li>
        )
      })}
    </ul>

  </div>)

}


export default BlogAllArticle;