import React from 'react'
import { Carousel, Image } from 'antd';
import { Link } from "react-router-dom"
import './index.css'

class MySwiper extends React.Component{
  constructor(props){
    super(props)
    console.log(this.props,111)
  }
  render(){
    if (!this.props || !this.props.kvdata || this.props.kvdata.length <1 ){
      return null;
    }else{
      return (<>
        <div className="swiperCont">
          <Carousel className="myswiper" ref="myswiper">
            {this.props.kvdata.map((item,indexs)=>{
              return (
                <Link to={{ pathname: '/u/profile/' + item.article_id }} key={item.article_id}>
                  <Image preview={false} src={item.pic} />
                </Link>
              )
            })}
          </Carousel>
          <div className="swiperBtn leftBtn" onClick={() => { this.refs.myswiper.prev() }}></div>
          <div className="swiperBtn rightBtn" onClick={() => { this.refs.myswiper.next() }}></div>
        </div>
      </>)
    }
    
  }
}

export default MySwiper;