import React from 'react'

import BlogSlotClass from '../../component/blogslot/blogslot'
import SynopsisComp from '../../component/blogSynopsis/synopsis'
import PersonalData from '../../component/blogpersonaldata/personaldata'
import VisitorCont from '../../component/blogvisitor/visitor'
import BlogHomeList from '../../component/bloghomelist/homelist'

import { AlterModal } from '../../utils/commonlyUsed'
import { AxiosPost } from '../../utils/AxiosPack';

// 博客个人的首页

let mainEvenListen;
let mainDie = false
class MainClass extends React.Component{
  constructor(props){
    super(props)
    this.state={
      backgroundImage2: "/img/home/blog_hard.jpg",
      blog_id: "",
      blogger_data: {},
      article_data: {},
      url_pathname :'',
      article_page:1,
      paginationTol:0
    }
  }
  componentDidMount() {
    mainDie = false;
    this.urlChageFunc();
    mainEvenListen = this.props.history.listen((a,b,c) => {
      setTimeout(() => {
        this.urlChageFunc(a.pathname);
      }, 10);
    })
  }
  componentWillUnmount() {
    mainDie = true;
    mainEvenListen && mainEvenListen(); // 执行解绑
  }
  urlChageFunc(a) {
    if (mainDie || a == this.state.url_pathname) {
      return null;
    }
    this.onLoaderDAta(a);
  }
  async onLoaderDAta(a,pages) {
  
    let page_num = pages ? pages:1;
    var blog_id = this.props.match.params.id;
    let res = await AxiosPost('/getBlogMain', { id: blog_id, nums: page_num });
    if (res.data.result == "-1") {
      AlterModal(res.data.data.act)
    } else {
      let obj = res.data.data;
      this.setState({
        blogger_data: obj.bloggerData,
        article_data: obj.articleDataObj,
        paginationTol:obj.tol,
        url_pathname:a
      })
    }
  }
  paginationChange = (page, pageSize)=>{
    if(page != this.state.article_page){
      this.setState({
        article_page:page
      })
      this.onLoaderDAta(this.state.url_pathname,page);
    }
  }
  render(){
    return(<>
      <BlogSlotClass userId={this.state.blogger_data.uid} userName={this.state.blogger_data.user_name} currentNav="main" >
        <SynopsisComp context={this.state.blogger_data.user_explain} data-postion="left"/> 
        <PersonalData Blogger={this.state.blogger_data}  data-postion="left" /> 
        <VisitorCont data-postion="left" visitordata={this.state.blogger_data.fangkearr} /> 
        <BlogHomeList paginationTol={this.state.paginationTol} paginationChange={this.paginationChange} articleDataObj={this.state.article_data} userId={this.state.blogger_data.uid} authObj={{ ...this.props.authObj }}  data-postion="main" />
      </BlogSlotClass>
    </>)
  }
}

export default MainClass