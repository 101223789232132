import React from "react"
import './visitor.css'

function dateFormat(str, fmt) {// 时间的处理
  let date = new Date(parseInt(str));
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "M+": (date.getMonth() + 1).toString(),     // 月
    "D+": date.getDate().toString(),            // 日
    "h+": date.getHours().toString(),           // 时
    "m+": date.getMinutes().toString(),         // 分
    "s+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    };
  };
  return fmt;
}

function VisitorCont(props) {
  console.log(props)
  if (props.visitordata === undefined){
    return null;
  }
  return (<div className='blogComp visitorCont'>
    <div className="title">访客</div>
    <ul>
      {props.visitordata.map((item,index)=>{
        return (
          <li key={index}>
            <img src="/img/fangke_ioc.png" />
            <a href={"/u/main/" + item[0]} target="_blank">{item[1]}</a>
            <div className="data">{dateFormat(item[2],"M月D日")}</div>
          </li>
        )
      })}
    </ul>
    
  </div>)

}

export default VisitorCont;