import React from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd'
import { AlterModal } from '../../utils/commonlyUsed'
import { AxiosPost } from '../../utils/AxiosPack';
import "./blogsearch.css"


// 写文章
let searchBlogEvenListen;
let searchBlogDie = false;

class BlogSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue:"",
      searchData:[],
      searchTol:1,
      article_num:1,
      pageMinH: 400,
    }
  }
  componentDidMount() {  //在第一次渲染后调用
    searchBlogDie = false;
    this.setState({
      pageMinH: document.body.clientHeight - 80
    })
    this.onLoaderDAta();
    searchBlogEvenListen = this.props.history.listen((a, b, c) => {
      setTimeout(() => {
        this.onLoaderDAta();
      }, 10);
    })
  }
  componentWillUnmount() {//消除组件前
    searchBlogDie = true;
    searchBlogEvenListen && searchBlogEvenListen(); // 执行解绑
  }
  componentWillReceiveProps(nextProps) {//props值改变的时候，调用
    if (this.props.authObj.is_login !== nextProps.authObj.is_login) {
      if (nextProps.authObj.is_login) {
        this.onLoaderDAta();
      }
    }
  }
  async onLoaderDAta(idx) {
    if (searchBlogDie) {
      return null;
    }
    var search_name = this.props.match.params.id;
    this.setState({
      searchValue: search_name
    })
    var obj={};
    obj.text = search_name;
    if(idx){
      obj.nums = idx;
    }

    let res = await AxiosPost('/searchblog', obj);
    if (res.data.result == "-1") {
      AlterModal(res.data.data.act)
    } else {
      this.setState({
        searchData: res.data.data.backObj,
        searchTol: res.data.data.tol
      })
    }
  }

  paginationChange = (page, pageSize) => {
    if (page != this.state.article_num) {
      this.setState({
        article_num: page
      })
      this.onLoaderDAta(page);
    }
  }




  render() {
    return (<>
      <div className="searchPage" style={{minHeight:this.state.pageMinH}}>
        <div className="searchPageCent">
          {this.state.searchData &&this.state.searchData.length >0 ? (
            <>
              <h1>找到了关于"<span>{this.state.searchValue}</span>"的文字</h1>
              {this.state.searchData.map((item,index)=>{
                return (
                  <div className="article" key={index}>
                    <div className="article_title_cont">
                      <Link to={{ pathname: '/u/profile/' + item.article_id }} >
                        {/* <div className="titles">{item.title}<span className="fangwen">访问次数（{item.liulan_num}）</span></div> */}
                        <div className="titles" dangerouslySetInnerHTML={{ __html: item.title }}></div>
                      </Link>
                      <div className="timers">{item.timer}</div>
                    </div>
                    <div className="article_body" dangerouslySetInnerHTML={{ __html: item.connet }}></div>
                    <div className="fangwen">访问次数（{item.liulan_num}）</div>
                  </div>
                )
              })}
            </>
          ) : (
            <>
                <h1>没有找到了关于"<span>{this.state.searchValue}</span>"的文字</h1>
            </>
          )}

          <Pagination
            hideOnSinglePage={true} //有一页的时候，隐藏
            defaultCurrent={1}  //默认的当前页数
            pageSize={10} //每页的条数
            total={this.state.searchTol} //总数
            showQuickJumper //是否显示快速跳转按钮
            size="small" //尺寸大小
            showSizeChanger={false}
            onChange={this.paginationChange}
          />
            
        </div>
      </div>
    </>)
  }
}

export default BlogSearch;