import React from 'react'
import { doMain } from '../../utils/AxiosPack';
import { Link } from "react-router-dom"
import './index.css'

class DaKaSay extends React.Component{
  constructor(props){
    super(props);

  }

  render(){
    if (!this.props || !this.props.dkdata || this.props.dkdata.length < 1) {
      return null;
    } else{
      return (<>
        <div className="dakaCont">
          <div className="titles">博客大咖说</div>
          <ul>
            {this.props.dkdata.map((item)=>{
              return (
                <li key={item.article_id}>
                  <img src={doMain() + item.heard} />
                  <div>
                    <p><Link to={{ pathname: '/u/profile/' + item.article_id }}>{item.content}</Link></p>
                    <span>{item.username}</span>
                  </div>
                </li>
              )
            })}
            
          </ul>
        </div>
      </>)
    }
  }
}


export default DaKaSay;