import React from 'react';
import LoginOutConnect from './loginOut'
import { Link } from 'react-router-dom';
import { BackUrl } from '../../utils/commonlyUsed'
class NavLogoIn extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    if (this.props.authObj.is_login) {
      //代表已经登陆
      return (<div className="pageloginCont">
        <div className='navlogin_in'>
          
          <Link to={{ pathname: '/u/main/' + this.props.authObj.uid, state: { prehref: this.props.prehref } }} >
            <div className='navlitm nav_user_name'> {this.props.authObj.user_name} </div>
          </Link>
          <Link to={{ pathname: '/u/my/' + this.props.authObj.uid, state: { prehref: this.props.prehref } }} >
            <div className='navlitm zhongxin'>个人中心</div>
           </Link>
          <div className='navlitm wirte_bowen'> <Link to={{ pathname: '/writeblog' }} >发博文</Link></div>
          <LoginOutConnect />
        </div>
      </div>)

    } else {
       //代表没有登陆
      return (<div className="pageloginCont">
        <div className='loginOut'>
          <div className='navlitm navlogin_btn' onClick={() => { this.props.showPage()}} >登陆</div>
          <div className='navlitm navlogin_btn'><Link to={{ pathname: '/signup', state: { prehref: BackUrl(this.props) } }} >立即注册</Link></div>
        </div>
      </div>)
    }
  }
}

export default NavLogoIn;