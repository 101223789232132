import { combineReducers } from 'redux'
import { DengluReducer} from './auth'
import { visibilityFilter} from './other'


const todoApp = combineReducers({
  DengluReducer,
  visibilityFilter
})

export default todoApp;